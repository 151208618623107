import Layout from "../../layout/layout";
import DriverListDownload from "./parts/driverListDownload";
import OderListPerMonth from "./parts/oderListPerMonth";
import OrderListCustomDate from "./parts/orderListCustomDate";
import OrderListByDish from "./parts/orderListByDish"; // Import the component
import OrderListDishDescription from "./parts/oderListDishDescription";

function OrderListPage() {
    

    const body = (
        <>
            <div>
                <DriverListDownload />
            </div>
            <div>
                <OderListPerMonth />
            </div>
            <div>
                <OrderListCustomDate /> 
            </div>
            <div>
                <OrderListByDish /> {/* Use the imported component */}
            </div>
            <div>
                <OrderListDishDescription /> {/* Use the imported component */}
            </div>

        </>
    );
    return <Layout body={body} title={"Order Lists Download"} />;

}

export default OrderListPage;
